:root {
    --background-color: #f6f6f6/*#384d5d*/;
    --text-primary: #292924;
    --text-light: #444;
    --text-lighter: #667;
    --text-lightest: #aaa;
    --offset: 16px;
    --offsethalf: calc(var(--offset)/2);
    --offset2: calc(var(--offset)*2);
    --callout-height: 100px;

    --chart-color1: #aaa;
    --chart-color2: #667;
    --sketch-inverse: 1;
    --logo-color: black;

    --highlight-color: #af7267;
    --form-element-radius: 4px;
}

/* Themes */
body.shiv {
    --background-color: #384d5d;
    --text-primary: #C7DAE9;
    --text-light: #B6C9D8;
    --text-lighter: #94A7A5;
    --text-lightest: #7c8b9c;
    
    --chart-color1: #7c8b9c;
    --chart-color2: #B6C9D8;
    --sketch-inverse: 0;
    --logo-color: #FFFFFF;
}


body.quilt {
    --highlight-color: #af7267;
}


html, body { margin: 0; padding:0;height: 100%;font-family: Public Sans;}
html {font-size:62.5%;}
body {font-size:2rem;color:var(--text-primary);}
p {margin:0;}

#root, .App {height:100%;}
.App {display: flex; justify-content: center; align-items: flex-start;}

h1, h2, h3, h4 {font-family: Outfit;line-height:1em;}
.h0 {font-size:6.5rem;color:var(--text-lighter);font-weight:600;margin-bottom:5rem;font-family:Outfit;}
h1 {/*color:var(--text-light)*/line-height:1.2em;position:absolute;margin:calc(var(--offset)*2);padding:0;font-size:4.5rem;color: var(--text-primary);}
h1 .prefix {color:var(--text-lightest);margin-right:calc(var(--offset)/2);}
h2 {font-size:3.9rem;font-weight:300;color:var(--text-primary);}
h3 {font-size:3rem;margin:0;color:var(--text-primary);}
h4 {font-size:2.5rem;margin:0;font-weight:300;color:var(--text-primary);}
h4 b {font-weight:400;}
li {line-height:1.8em;}
button.primary {
    font-size: 16px;padding:10px 24px;box-shadow: 0px 2px 5px 0px #0000004D;color:white;font-family: Public Sans;
    border:0;border-radius:20px;background:linear-gradient(226.08deg, #0077ff 12.74%, #00f 178.42%);font-weight:bold;
}


div.slide-content {gap:calc(var(--offset)*2);padding:calc(var(--offset)*2);width: 100%;display:grid;padding-top:12rem;}
table.slide-content {width: 100%;margin:12rem calc(var(--offset)*2) calc(var(--offset)*2) calc(var(--offset)*2);width:100%;}
table.slide-content th {background-color: red;}

.section-label {text-transform: uppercase; color: var(--text-lighter);font-size: 1.4rem;}
.explanation {color: var(--text-lightest);}

.picture-placeholder {background: radial-gradient(rgba(255,255,255,0), rgba(0,0,0,0.05));width: 100%;height: 100%;border: 1px solid #ddd;display: flex;align-items: center;justify-content: center;text-transform: uppercase;color: var(--text-primary);border-radius: var(--offset);}

.venn {width:80rem;height:80rem;position:relative;margin:auto;}
.venn-circle {width:60%;height:60%;border-radius:50%;position:absolute;background: radial-gradient(rgba(0,0,0,0.04), rgba(0,0,0,0.07)); display:flex;align-items:center;justify-content:center;}
.venn-pointer {position: absolute;width:550px;left:60rem;top:50%;margin:3px 0 0 -10px;background:var(--text-primary);}

hr {height:1px;border:0;background:var(--text-lighter);}
.interstitial-content {flex-grow:1;padding:var(--offset);display:flex;justify-content:center;align-items:center;}
.interstitial-content h2 {margin-bottom:0;margin-top:-0.5em;font-size:10rem;}

.callout {text-align:center;}
.pill {padding:2px 8px; border-radius: 4px;color: white;background:var(--highlight-color);}

.notes {position:absolute;top:0;right:0;margin:0;width:60rem;padding:0 calc(var(--offset)*2);background:rgba(255,255,255,0.7);backdrop-filter:blur(5px);color:var(--text-primary);height:100%}
.notes li {margin: calc(var(--offset)*2) var(--offset) 0;font-size:1.8rem;}


.hero-shot {background-repeat:no-repeat;background-size:cover;background-position:center;border-radius:calc(var(--offset)/2);/*box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1)*/;}
.stat h3 {font-size:3.9rem;}


.tick-label {font-size: 2rem; fill:var(--text-primary);font-weight:200;}


/*Cover*/
.cover h1 {font-size:13rem;/*background:url('../public/img/textures/black-mesh.png');background-clip:text;color:transparent;background-size:130% auto;*/position:relative;}
.tag {background:var(--text-lighter);padding:2px 8px;border-radius:8px;color:white;font-size:2rem;display:inline-block;font-family: Outfit;}

/*Problem*/
.stat-column {align-self:start;text-align:center;align-items:start;}
.stat {font-size:10rem;font-weight:300;padding-top:4rem;font-family:Outfit;color: var(--text-lighter);font-weight:bold;}
.stat em {font-size:3.9rem;font-style: normal;padding-left:calc(var(--offset)/2);}
.stat-explanation {font-size:2rem;color:var(--text-light);width:450px;margin:2rem auto;}
.stat-explanation b {font-weight:600;}
.graph-container {height:450px;display:flex;flex-direction:row;justify-content:center;position:relative;}
.graph-container hr {width:100px;margin:calc(var(--offset));}
.graph-container .stack {display:flex;flex-direction:row;}
.graph-container .stack h2 {margin:0;}
.graph-container .stack .description {color:var(--text-light);}

/*Market*/
.market {display:flex;padding-bottom:170px;justify-content:center;font-family:Outfit;}
.market > div {flex:1;max-width:400px;margin:0 var(--offset2);}
.market h3 {text-align:center;margin-bottom:40px;}
.row-titles {padding:102px 32px 0;font-size:3rem;font-weight:300;}
.row-titles div {margin-left:80px;}
.row-titles div, .spotlight-data {line-height: 13rem;padding:var(--offset2);}
.market .spotlight-data {font-size:7.2rem;font-weight:bold;color:var(--text-lighter);text-align:right;padding-right:100px;}
.data-column {padding:var(--offset2);border-radius:20px;background: rgba(255, 255, 255, 0.5);backdrop-filter: blur(10px);}
.data-column div:not(:last-child) {border-bottom:1px solid #00000035;}


/*Solution*/
.slide.split {background: linear-gradient(180deg, rgb(185, 182, 174) 0%, rgba(184,181,173,.9) 4%, rgba(180,178,165,.8) 16%, rgba(179,175,163,.8) 35%, rgba(179,176,161,.8) 40%, rgb(212, 210, 202) 40%, rgba(212, 210, 202) 100%);}
.stage-column {align-self:start;padding-top:120px;}
.stage-divider {font-size:6rem;align-self:start;margin-top:10.5rem;text-align:center;color:rgba(22,22,22,.4);}
.stage-column .stage {font-size:3.9rem;text-align:center;font-family:Outfit;}
.stage-column ul, ul.features {font-size:2.5rem;margin:4rem 0 0 3rem;overflow:hidden;}

/*Why Delivery*/
.why-delivery .column {flex:1;padding:0 var(--offset2);}
.why-delivery .column:first-child {padding-right:80px;border-right:1px solid var(--text-lightest);}
.why-delivery .column:last-child {padding-left:100px;}
.why-delivery .column .tag {margin-left:var(--offset2);}
.why-delivery .raw-points {width:700px;}
.why-delivery .raw-points li {height:150px;}
.raw-points {padding:0;list-style:none;text-align:left;}
.raw-points li{padding:var(--offset2);}
.raw-points .main {font-size:3.9rem;font-family:Outfit;padding-bottom:var(--offsethalf);}

/*Unit Economics*/
.unit-economics {--ue-bar-width: 300px;}
.unit-economics .column {flex:1;padding:0 var(--offset2);display:flex;flex-direction:column;justify-content:center;}
.unit-economics .column:first-child {padding-right:40px;/*border-right:1px solid var(--text-lightest);*/}
.unit-economics .tag {margin:auto;}
.unit-economics .total {padding-top:var(--offset2);margin-top:var(--offset2);border-top:3px solid var(--text-light);font-size:3r;text-align:right;}
.unit-economics .column:last-child {padding-left:40px;}
.bar {width:var(--ue-bar-width);height:600px;margin-top:var(--offset2);}
.bar .component {position:relative;}
.bar .label {position:absolute;width:var(--ue-bar-width);left:calc(var(--ue-bar-width)* -1);display:flex;height:100%;align-items:center;}
.bar .rule {flex:1;border-top:1px solid var(--text-lightest);margin:0 var(--offset);}
.bar .capture {position:absolute;bottom:0;border-top:1px solid #182d3a;border-bottom:1px solid #182d3a;width:100%;}

.bar.main .component:first-child {background:#617386;border-top-left-radius:20px;border-top-right-radius:20px;}
.bar.main .component:not(:first-child) {background:linear-gradient(to bottom, transparent, transparent 3px, #617386 3px);}
.bar.main .component:last-child {border-bottom-left-radius:20px;border-bottom-right-radius:20px;}
.bar.main .capture {background:repeating-linear-gradient(-45deg,#415664,#415664 10px, transparent 10px, transparent 20px);}
.bar.main .amount {text-align:right;color:white;visibility:hidden;}

.bar.capture .capture {background-color:#415664;}
.bar.capture .capture-label {position:absolute;left:300px;width:100%;text-align:right;display:flex;position:relative;top:-.5em}
.bar.capture .rule {position:relative;top:.6em;}

/*Team*/
.team {--avatar-side:20rem}
.team .tag {position:absolute;}
.team .people {display:flex;padding:6rem 8rem;}
.team .person {min-width: 700px;flex:1;display:grid;grid-column-gap:var(--offset2);grid-template-columns:var(--avatar-side) 1fr;grid-template-rows:4rem 4rem 1fr;}
.team .photo {grid-row:1 / 4;height:var(--avatar-side);width:var(--avatar-side);border-radius:50%;border:1px solid var(--text-lighter);margin-right:var(--offset);}
.team .title {font-size:1.8rem;color: var(--text-lighter);}
.team .bio p {padding-bottom:var(--offset);}

/*Hardware*/
.view-container {flex:1;}
.view-container img {max-width:100%;max-height:100%;margin:auto;}
.features-container {width:400px;}
.features-container .features {overflow:visible;}
.hardware .features {margin-left:80px;}
.features li {margin-right:-50px;}
.features .title {list-style: none;margin-left: -30px;font-size: 1.8rem;color: var(--text-lighter);}
.features .clarification {font-size:1.8rem;color:var(--text-light);line-height:2rem;display:none;}

/*Software*/
/* .slide.mellow {background: radial-gradient(circle at center, #f0f0f0 0%, #e0e0e0 100%);} */
.slide.mellow .slide-content {display:block;}
.version {position:absolute;}
.callout-line {width:2px;height:120px;position:absolute;margin-top:-20px;z-index:5;
    background: linear-gradient(to bottom, #182d3a, #81acd4 180px);
}
.interface-container {border-radius:30px;border:10px solid rgba(0,0,0,.2);display:flex;}
.interface {
    display:flex;border-radius:20px;align-items:stretch;flex:1;box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color:rgb(241,242,245);position:relative;
}
.interface::before {content:"";position:absolute;width:100%;height: 100%;z-index:0;
    background: radial-gradient(circle at 30% 80%, #F64F8220, transparent 25%),
    radial-gradient(circle at 0% 35%, #1EA8F510, transparent 25%),
    radial-gradient(circle at 90% 30%, #F64F8210, transparent 25%),
    radial-gradient(circle at 60% 15%, #1EA8F515, transparent 25%);
}

.interface .chat {flex:1;display:flex;flex-direction:column;margin:var(--offset);margin-right:0;z-index:2;position: relative;
    background: rgba(255, 255, 255, 0.5);backdrop-filter: blur(10px);border-radius:8px;padding-top: 8px;min-width:380px;max-height:700px;
}
.interface .chat:before {content:"";position:absolute;height:40px;width:100%;top:0;border-radius: 8px;
    background: linear-gradient(to bottom, white 8px, transparent 40px);border-radius:8px;
}
.interface .chat .conversation {flex:1  ;overflow:auto;display:flex;flex-direction:column;justify-content:flex-end;}
.conversation .message {font-size:1.4rem;line-height:2.4rem;border-radius:var(--form-element-radius);padding:var(--offset);}
.message.user {background-color:white;text-align:right;}
.message.bot {color: var(--text-lighter);}
.message:last-child {padding-bottom:var(--offsethalf);}
/* .message.user+.message.bot {background: linear-gradient(to bottom, white, transparent 40px);} */

.product-list {display:flex;padding:0;overflow:hidden;margin-right:calc(var(--offset)*-1);}
.product-list li {list-style:none;border-radius:8px;background-color:white;border:3px solid white;box-shadow: 0px 4px 20px 0px #0000000D;
    width:120px;height:130px;margin-right:var(--offset);display:flex;flex-direction:column;padding:var(--offsethalf);font-size:1.4rem;
}
.product-list li img {flex:3;max-width:100%;max-height:80px;}
.product-list li .name {flex:1;white-space: nowrap;overflow:hidden;text-overflow:ellipsis;font-weight:400;}


.omnibox {display:flex;flex-direction:column;
    border-radius: 10px;background-color:white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);border:1px solid rgba(255,255,255,.3);
}
.omnibox .micro-form {border-bottom:1px solid white;padding:var(--offset);}
.omnibox .reply-input {padding:var(--offset);flex:1;border-top:1px solid #00000005;font-size:1.6rem;color:var(--text-lightest);}

.interface .shopping-list {flex:2;display:flex;}
.cart-table {border-collapse:collapse;overflow: auto;font-size:1.4rem;flex:1;margin:var(--offset);z-index:2;}
.cart-thead, .cart-tfoot {position:sticky; top: 0; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);z-index:2;}
.cart-thead {box-shadow: 0px 1px 6px 0px #00000026;border-top-left-radius:8px;border-top-right-radius:8px;}
.cart-thead th:first-child {border-top-left-radius:8px;}
.cart-thead th:last-child {border-top-right-radius:8px;}

.cart-th, .cart-td {padding:calc(var(--offset)/2);font-weight:normal;background-color:white;}
.cart-th {background-color:white;}
.cart-table tbody {background: rgba(255, 255, 255, 0.5);backdrop-filter: blur(10px);}
.cart-table tbody tr {height:72px;}
.cart-thumbnail {width: 64px; height: 64px; border-radius: 4px;}
.cart-td {border-bottom:1px solid #ededed;}
.cart-td .remove-item {cursor: pointer; font-size: 24px;padding:var(--offset);}

.cart-tfoot {top:auto; bottom:0;height:72px;border-bottom-left-radius:8px;border-bottom-right-radius:8px;}
.cart-tfoot td {border-bottom-left-radius:8px;border-bottom-right-radius:8px;background-color:white;}
.footer-content {padding:var(--offset);display:flex;justify-content:flex-end;}

.store-row {background-color:white;border-bottom:1px solid #ededed;padding-left:calc(var(--offset)*2);}
.store {display:inline-flex;align-items:center;}
.store h4 {display:inline-block;height:100%;}

.product-stack img {float:left;margin:0 calc(var(--offset)/2) 0 var(--offset);}
.double-decker {padding:var(--offset)}
.double-decker .main {font-weight:400;}
.double-decker .clarification {font-size:1.2rem;padding-top:calc(var(--offset)/4);color:var(--text-lighter);white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.cart-quantity-control {display:inline-flex;line-height:3rem;}
.cart-quantity-control input {border:none;width:50px;text-align:center;border-top:1px solid #dadada;border-bottom:1px solid #dadada;}
.cart-quantity-control .trigger {width:35px;text-align:center;background-color:rgb(241,242,245);border:1px solid #dadada;}
.cart-quantity-control .trigger.minus {border-right:1px solid #dadada;border-top-left-radius:var(--form-element-radius);border-bottom-left-radius:var(--form-element-radius);}
.cart-quantity-control .trigger.plus {border-left:1px solid #dadada;border-top-right-radius:var(--form-element-radius);border-bottom-right-radius:var(--form-element-radius);}
.cart-td.rating {text-align:center;width:100px;}